<template>
  <div class="success">
    <div class="success-box">
      <img class="success-box-img" src="@/assets/success.png" alt="">
      <div class="success-box-title">恭喜您~</div>
      <div class="success-box-text">合同【{{signatureDetails.bizName}}】已签署完成</div>
      <div class="success-box-btn">
        <div class="success-box-btn-detail" v-if="downShow" @click="download">下载合同</div>
      </div>
      <div class="success-box-btn">
        <div class="success-box-btn-detail" @click="lookD">查看合同详情</div>
      </div>
      <div class="success-box-btn"  v-if="type == 'miniWx'">
        <div class="success-box-btn-detail" @click="lookDm">合同管理</div>
      </div>
    </div>
    <div class="use-list" v-if="type != 'miniWx'" @click="listClick()">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { queryDocFilesByDocumentNosAndTenantId } from '@/util/api'
// import { downloadFile } from '@/util/tool'
import { mapState } from 'vuex'

export default {
  data(){
    return{
      fileObj: '',
      offD: false,
    }
  },
  computed: {
    ...mapState(['linkCodeInfo', 'signatureDetails']),
    downShow () {
      const { process = [] } = this.signatureDetails
      const actionStatus = process.reduce((pre, curr) => {
        if (curr.actionStatus === 1) {
          return pre = [...pre, curr]
        }
        return pre
      }, [])
      this.getPdfUrl(this.signatureDetails)
      return actionStatus.length === 1
    },
    type () {
      return sessionStorage.getItem('sourceType')?sessionStorage.getItem('sourceType'): ''
    }
  },
  methods: {
    getPdfUrl(val) {
      let finalDocNo = this.$route.query.finalDocNo
      if(!finalDocNo)return
      queryDocFilesByDocumentNosAndTenantId({
        documentNos: [finalDocNo],
        tenantId: val.tenantId,
      }).then((res) => {
        console.log(res)
        this.fileObj = res[finalDocNo][0]&&res[finalDocNo][0];
      });
    },
    listClick() {
      this.$router.push({path: '/usePage',query:{}})
    },
    download() {
      if(this.type == 'miniWx'){
        // eslint-disable-next-line no-undef
        this.copyUrl()
      }else {
        window.open(this.fileObj.fileUrl);
      }
    },
    copyUrl() {
      let input = document.createElement("input");
      input.value = this.fileObj.fileUrl;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('链接复制成功，请在浏览器打开下载')
    },
    lookD() {
      // debugger
      if(this.type == 'miniWx'){
        // this.$router.push({ path: '/sign',query:{signatureId:this.signatureDetails.id}})
        // const params = {
        //   id: this.signatureDetails.id,
        //   type: 2,
        // };
        // eslint-disable-next-line no-undef
        wx.miniProgram.reLaunch({
          url: `/contractManagement/contractPage/detail?id=${this.signatureDetails.id}`,
        });
      } else {
        this.$router.push({ path: 'home' })
      }
    },
    lookDm() {
      // eslint-disable-next-line no-undef
      wx.miniProgram.reLaunch({
        url: `/pages/contractM/index`,
      });
    }
  }

}
</script>

<style lang='less' scoped>
.success {
  width: 100%;
  height: 100%;
  background: #EEF3FC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .use-list {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    top: 400px;
    right: 10px;
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 1px rgba(70,70,70,0.08);
    border-radius: 25px;
    opacity: 1;
    span {
      display: inline-flex;
      width: 20px;
      height: 4px;
      background: #8B96A6;
      border-radius: 1px;
    }
    span:nth-child(2) {
      margin: 3px 0px;
    }
  }
  &-box {
    width: 256px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      width: 131px;
      height: 112px;
    }
    &-title {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 34px;
      color: #30586C;
      opacity: 1;
      margin-top: 32px;
    }
    &-text {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 19px;
      color: #30586C;
      opacity: 1;
      margin-top: 12px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // flex-wrap: wrap;
      margin-top: 28px;
      &-down {
        width: 148px;
        height: 44px;
        border: 1px solid #DCDCDC;
        opacity: 1;
        border-radius: 4px;
        text-align: center;

        box-sizing: border-box;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 44px;
        color: #8B96A6;
        opacity: 1;
        margin-bottom: 16px;
        margin-right: 16px;
      }
      &-detail {
        width: 148px;
        height: 44px;
        background: rgba(0, 103, 225, 0.03);
        opacity: 1;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 44px;
        color: #0067E1;
        opacity: 1;
      }
    }
  }
}
</style>